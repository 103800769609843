<template>
   <section>
      <q-permissions
         :__PERMISSIONS__="permStatus"
         :page="'personnel'"
         :index="'index'"
      />
      <div class="" v-if="permStatus">
         <!-- Modal pour ajouter une nouvelle taxe -->
         <b-modal
            id="modal-login"
            cancel-variant="outline-secondary"
            ok-title="Créer"
            cancel-title="Annuler"
            centered
            title="Ajouter un nouveau employé"
            @ok="handleOk"
         >
            <validation-observer ref="registerForm">
               <b-form class="auth-register-form mt-2">
                  <div>
                     <h5 class="text-danger text-center">{{ user_exist }}</h5>
                  </div>

                  <!-- Role 
               <b-form-group label-for="entreprise-domaine">
                  <label for=""
                     >Role <span class="p-0 text-danger h6"> *</span></label
                  >
                  <validation-provider
                     #default="{  }"
                     name="nom"
                     rules="required"
                  >
                     <v-select
                        v-model="selectedRole"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="roles"
                        placeholder="Selectionner un role"
                     />

                     <small
                        :class="valideRole ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Veuillez selectionner le role de l'employe
                     </small>
                  </validation-provider>
               </b-form-group>

               -->

                  <!-- nom -->
                  <b-form-group label-for="register-nom">
                     <label for=""
                        >Nom <span class="p-0 text-danger h6"> *</span></label
                     >
                     <validation-provider
                        #default="{ errors }"
                        name="nom"
                        rules="required"
                     >
                        <b-form-input
                           id="register-nom"
                           @input="validateNom"
                           v-model="nom"
                           name="register-nom"
                           :state="errors.length > 0 ? false : null"
                           placeholder="Sika"
                        />
                        <small
                           :class="valideNom ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Vous devez renseigner le nom de l'employé
                        </small>
                     </validation-provider>
                  </b-form-group>

                  <!-- prenom -->
                  <b-form-group label-for="register-prenom">
                     <label for=""
                        >Prénoms
                        <span class="p-0 text-danger h6"> *</span></label
                     >
                     <validation-provider
                        #default="{ errors }"
                        name="prenom"
                        rules="required"
                     >
                        <b-form-input
                           id="register-prenom"
                           @input="validatePrenom"
                           v-model="prenom"
                           name="register-prenom"
                           :state="errors.length > 0 ? false : null"
                           placeholder="John"
                        />
                        <small
                           :class="validePrenom ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Vous devez renseigner le prénom de l'employé
                        </small>
                     </validation-provider>
                  </b-form-group>

                  <!-- email -->
                  <b-form-group label-for="register-email">
                     <label for=""
                        >Email <span class="p-0 text-danger h6"> *</span></label
                     >
                     <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                     >
                        <b-form-input
                           id="register-email"
                           @input="validateEmail"
                           v-model="email"
                           type="email"
                           name="register-email"
                           :state="errors.length > 0 ? false : null"
                           placeholder="john@example.com"
                        />
                        <small
                           :class="valideEmail ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Veuillez entrer l'email de l'employé
                        </small>
                        <small
                           :class="ifMailExist ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Un employé avec cet email existe déjà
                        </small>
                     </validation-provider>
                  </b-form-group>

                  <!-- localisation -->
                  <b-form-group
                     label-for="entreprise-localisation"
                     class="localisation"
                  >
                     <label for=""
                        >localisation
                        <span class="p-0 text-danger h6"> *</span></label
                     >
                     <validation-provider
                        #default="{ }"
                        name="localisation"
                        rules="required"
                     >
                        <div id="custom-search-input" class="mb-1">
                           <div class="input-group">
                              <input
                                 id="autocomplete_search"
                                 @input="initialize"
                                 name="autocomplete_search"
                                 type="text"
                                 class="form-control"
                                 placeholder="Votre adresse"
                              />
                              <input type="hidden" name="lat" />
                              <input type="hidden" name="long" />
                           </div>
                        </div>
                        <small
                           :class="valideLocalisation ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Veuillez entrer la localisation du client
                        </small>
                     </validation-provider>
                  </b-form-group>

                  <!--  -->

                  <!-- password -->
                  <b-form-group label-for="register-password">
                     <label for=""
                        >Mot de passe
                        <span class="p-0 text-danger h6"> *</span></label
                     >
                     <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="required"
                     >
                        <b-input-group
                           class="input-group-merge"
                           :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                           <b-form-input
                              id="register-password"
                              @input="validatePassword"
                              v-model="password"
                              class="form-control-merge"
                              :type="passwordFieldType"
                              :state="errors.length > 0 ? false : null"
                              name="register-password"
                              placeholder="············"
                           />
                           <b-input-group-append is-text>
                              <feather-icon
                                 :icon="passwordToggleIcon"
                                 class="cursor-pointer"
                                 @click="togglePasswordVisibility"
                              />
                           </b-input-group-append>
                        </b-input-group>
                        <small
                           :class="validePassword ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Le mot de passe est de 8 caractrères minimum
                        </small>
                     </validation-provider>
                  </b-form-group>

                  <!-- contact -->
                  <b-form-group label-for="register-contact">
                     <label for=""
                        >Contact
                        <span class="p-0 text-danger h6"> *</span></label
                     >
                     <validation-provider
                        #default="{ errors }"
                        name="contact"
                        rules="required"
                     >
                        <vue-tel-input
                           id="register-contact"
                           @country-changed="changer"
                           @validate="contactEntier($event)"
                           v-model="phone"
                           name="register-contact"
                           :state="errors.length > 0 ? false : null"
                           placeholder="000-000-000-000"
                        />
                        <small
                           :class="valideContact ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Veuillez renseigner le contact de l'employé
                        </small>
                        <small
                           :class="valideContactNumber ? 'block' : 'none'"
                           class="text-danger"
                        >
                           Ce numéro de téléphone n'est pas valide
                        </small>
                     </validation-provider>
                  </b-form-group>
               </b-form>
            </validation-observer>

            <template #modal-footer>
               <b-button
                  :disabled="loading === true ? true : false"
                  variant="primary"
                  @click.stop.prevent="handleOk"
               >
                  <span v-if="loading === false">Ajouter</span>
                  <b-spinner
                     v-if="loading === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>

         <!-- Modal pour mettre à jour un employée déjà ajouté    -->

         <b-modal
            id="modal-update"
            cancel-variant="outline-secondary"
            ok-title="Modifier"
            cancel-title="Annuler"
            centered
            title="Modifier mon employe"
            @ok="updateUsers"
         >
            <b-form class="auth-register-form mt-2">
               <!-- Role -->
               <b-form-group label-for="entreprise-domaine">
                  <label for=""
                     >Role <span class="p-0 text-danger h6"> *</span></label
                  >
                  <validation-provider
                     #default="{  }"
                     name="nom"
                     rules="required"
                  >
                     <v-select
                        v-model="selectedEditRole"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="roles"
                        placeholder="Selectionner un role"
                     />

                     <small
                        :class="valideEditRole ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Veuillez entrer le role de l'employe
                     </small>
                  </validation-provider>
               </b-form-group>

               <!-- nom -->
               <b-form-group label-for="register-nom">
                  <label for=""
                     >Nom <span class="p-0 text-danger h6"> *</span></label
                  >
                  <validation-provider
                     #default="{ errors }"
                     name="nom"
                     rules="required"
                  >
                     <b-form-input
                        id="register-nom"
                        @input="validateEditNom"
                        v-model="edit_nom"
                        name="register-nom"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Sika"
                     />
                     <small
                        :class="valideEditNom ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Vous devez renseigner le nom de l'employé
                     </small>
                  </validation-provider>
               </b-form-group>

               <!-- prenom -->
               <b-form-group label-for="register-prenom">
                  <label for=""
                     >Prénom(s)
                     <span class="p-0 text-danger h6"> *</span></label
                  >
                  <validation-provider
                     #default="{ errors }"
                     name="prenom"
                     rules="required"
                  >
                     <b-form-input
                        id="register-prenom"
                        @input="validateEditPrenom"
                        v-model="edit_prenom"
                        name="register-prenom"
                        :state="errors.length > 0 ? false : null"
                        placeholder="john"
                     />
                     <small
                        :class="valideEditPrenom ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Vous devez renseigner le prénom du client
                     </small>
                  </validation-provider>
               </b-form-group>

               <!-- email -->
               <b-form-group label-for="register-email">
                  <label for=""
                     >Email <span class="p-0 text-danger h6"> *</span></label
                  >
                  <validation-provider
                     #default="{ errors }"
                     name="Email"
                     rules="required"
                  >
                     <b-form-input
                        id="register-email"
                        @input="validateEditEmail"
                        v-model="edit_email"
                        type="email"
                        name="register-email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="john@example.com"
                     />
                     <small
                        :class="valideEditEmail ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Veuillez entrer l'email du employé
                     </small>
                  </validation-provider>
               </b-form-group>

               <!-- Localisation -->
               <b-form-group
                  label-for="entreprise-localisation"
                  class="localisation"
               >
                  <label for=""
                     >Localisation
                     <span class="p-0 text-danger h6">*</span></label
                  >
                  <validation-provider
                     #default="{ }"
                     name="localisation"
                     rules="required"
                  >
                     <div id="custom-search-input" class="mb-1">
                        <div class="input-group">
                           <input
                              id="autocomplete_search"
                              @input="initialize"
                              name="autocomplete_search"
                              type="text"
                              v-model="edit_localisation"
                              class="form-control"
                              placeholder="Votre adresse"
                           />
                           <input type="hidden" name="lat" />
                           <input type="hidden" name="long" />
                        </div>
                     </div>
                     <small
                        :class="valideEditLocalisation ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Veuillez entrer la localisation de du prospect
                     </small>
                  </validation-provider>
               </b-form-group>

               <!-- password 
        <b-form-group label-for="register-password">
          <label for=""
            >Mot de passe <span class="p-0 text-danger h6"> *</span></label
          >
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password"
                @input="validateEditPassword"
                v-model="passwordEdit"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              :class="valideEditPassword ? 'block' : 'none'"
              class="text-danger"
            >
              Le mot de passe est de 8 caractrères minimum
            </small>
          </validation-provider>
        </b-form-group>
-->
               <!-- contact -->
               <b-form-group label-for="register-contact">
                  <label for=""
                     >Contact <span class="p-0 text-danger h6"> *</span></label
                  >
                  <validation-provider
                     #default="{ errors }"
                     name="contact"
                     rules="required"
                  >
                     <vue-tel-input
                        id="register-contact"
                        @country-changed="edit_changer"
                        @validate="edit_contactEntier($event)"
                        v-model="edit_phone"
                        name="register-contact"
                        :state="errors.length > 0 ? false : null"
                        placeholder="000-000-000-000"
                     />
                     <small
                        :class="edit_valideContact ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Veuillez renseigner votre contact
                     </small>
                     <small
                        :class="edit_valideContactNumber ? 'block' : 'none'"
                        class="text-danger"
                     >
                        Ce numéro de téléphone n'est pas valide
                     </small>
                  </validation-provider>
               </b-form-group>
            </b-form>

            <template #modal-footer>
               <b-button
                  :disabled="loading1 === true ? true : false"
                  variant="primary"
                  @click.stop.prevent="updateUsers"
               >
                  <span v-if="loading1 === false">Modifier</span>
                  <b-spinner
                     v-if="loading1 === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>

         <!--  -->
         <!-- Tableau pour afficher les employes -->
         <!-- <div class="tableau">
            <table class="table position-relative table-card table-bordered mt-5 pt-3">
                <b-button variant="relief-primary" v-b-modal.modal-login class="add-btn position-absolute">
                    Ajouter
                </b-button>
                <thead>
                    <tr class="text-center">
                        <th class="align-middle" scope="col">#</th>
                        <th class="align-middle" scope="col">Nom</th>
                        <th class="align-middle" scope="col">Prenoms</th>
                        <th class="align-middle" scope="col">Email</th>
                        <th class="align-middle" scope="col">contact</th>
                        <th class="align-middle" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody id="tbody">
                    <tr class="text-center" id="table_line" v-for="(user, index) in users" :key="user.id">
                        <th class="align-middle col-1" scope="row">{{ index + 1 }}</th>
                        <td class="col">
                            <p class="a" @click="detailEmploye(index)">{{ user.nom }}</p>
                        </td>
                        <td class="col">
                            <p>{{ user.prenoms }}</p>
                        </td>
                        <td class="col">
                            <p>{{ user.email }}</p>
                        </td>
                        <td class="col">
                            <p>{{ user.indicateur}} {{ user.contact }}</p>
                        </td>
                        <td class="col-3">
                            <div class="w-50 mx-auto d-flex justify-content-around">
                                <b-button variant="gradient-primary" class="btn-icon mr-2 edit-color" v-b-modal.modal-update @click="update(index)">
                                    <feather-icon icon="Edit3Icon" />
                                </b-button>
                                 <b-button variant="gradient-info" class="btn-icon mr-1 edit-color" @click="detailEmploye(index)">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button variant="gradient-danger" class="btn-icon" @click="confirmText(user.id,index)">
                                    <feather-icon icon="Trash2Icon" />
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> -->

         <!--  -->
         <b-card no-body class="pt-1">
            <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouveau employé -->
            <b-row class="px-2">
               <!-- Per Page -->
               <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
               >
                  <label>Entrées</label>
                  <v-select
                     v-model="perPage"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     :options="perPageOptions"
                     :clearable="false"
                     class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                  <b-button variant="primary" v-b-modal.modal-login>
                     <q-button-created title="Nouveau employé" />
                  </b-button>
               </b-col>

               <!-- Search -->
               <b-col cols="12" md="6" class="mt-1">
                  <div class="d-flex align-items-center justify-content-end">
                     <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                           <feather-icon icon="SearchIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                           v-model="filtreUser"
                           class="d-inline-block mr-1"
                           placeholder="Rechercher par : nom d'employé, contact, adresse..."
                        />
                     </b-input-group>
                  </div>
               </b-col>
            </b-row>

            <q-loader-table
               :success="state.success"
               :empty="state.empty"
               :warring="state.warring"
            />

            <!-- Le tableau affichant les taxes -->
            <b-table
               hover
               responsive
               primary-key="id"
               :per-page="perPage"
               :current-page="currentPage"
               :items="users"
               :fields="tableColumns"
               :filter="filtreUser"
               show-empty
               empty-text="Aucun employé"
               class="bg-white"
               v-if="state.success === true"
            >
               <template #row-details="row">
                  <div class="flex flex-col bg-white p-[24px] gap-8">
                     <div class=" grid grid-cols-1 md:grid-cols-2 gap-4   ">
                        <div
                           v-for="(elt, index) in xGetAllPermission"
                           :key="index"
                           class="border-[1px] "
                        >
                           <span class="flex border-b-[1px] p-[12px]">
                              {{ elt.name }}
                           </span>
                           <div class="w-full grid grid-cols-2 gap-2 p-[12px] ">
                              <div
                                 v-for="(permission, index) in elt.permissions"
                                 :key="index"
                              >
                                 <b-form-checkbox
                                    v-model="
                                       selectedPersonal[row.index][row.item.id]
                                    "
                                    :value="permission"
                                 >
                                    {{ permission }}
                                 </b-form-checkbox>
                              </div>
                           </div>
                        </div>
                     </div>

                     <b-button
                        size="sm"
                        class="w-6/12 md:w-3/12 p-[8px] "
                        variant="outline-secondary"
                        @click="updatePermissions(row.item.id)"
                     >
                        Sauvegade
                     </b-button>
                  </div>
               </template>

               <template #cell(details)="row">
                  <div class="py-50 flex items-center">
                     <label :for="`voir_plus_details_invoice${row.item.id}`">
                        <feather-icon
                           v-model="row.detailsShowing"
                           @change="row.toggleDetails"
                           :icon="
                              row.detailsShowing ? 'UnlockIcon' : 'LockIcon'
                           "
                           size="16"
                           class="cursor-pointer ml-1"
                        />
                     </label>

                     <b-form-checkbox
                        :id="`voir_plus_details_invoice${row.item.id}`"
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con hidden"
                        @change="row.toggleDetails"
                     >
                        <span class="vs-checkbox">
                           <span class="vs-checkbox--check">
                              <i class="vs-icon feather icon-check" />
                           </span>
                        </span>
                        <span class="vs-label">{{
                           row.detailsShowing ? 'Cacher' : 'Voir'
                        }}</span>
                     </b-form-checkbox>
                  </div>
               </template>

               <template #cell(nom)="data">
                  <div>
                     <span :id="`employe-row-${data.item.id}-nom-icon`">
                        {{
                           (data.item.nom + ' ' + data.item.prenoms)
                              | toSubtr(15)
                        }}
                     </span>
                     <b-tooltip
                        v-if="
                           `${data.item.nom.length +
                              ' '.length +
                              data.item.prenoms.length}` > 15
                        "
                        :title="data.item.nom + ' ' + data.item.prenoms"
                        class="cursor-pointer"
                        :target="`employe-row-${data.item.id}-nom-icon`"
                     />
                     <div
                        style="font-size: 10px"
                        class="mt-50 flex items-center"
                     >
                        <feather-icon icon="ChevronRightIcon" size="12" />
                        <b-badge
                           :variant="
                              data.item.status_user === 'employee'
                                 ? 'info'
                                 : 'primary'
                           "
                           >{{ data.item.status_user }}
                        </b-badge>
                     </div>
                  </div>
               </template>

               <!-- Bouton d'action -->
               <template #cell(actions)="data">
                  <!-- <b-button variant="gradient-primary" class="btn-icon mr-1 edit-color" v-b-modal.modal-update @click="update(data.item)">
                        <feather-icon icon="Edit3Icon" />
                    </b-button>
                    <b-button variant="gradient-info" class="btn-icon mr-1 edit-color"  @click="detailEmploye(data.item)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button variant="gradient-danger" class="btn-icon" @click="confirmText(data.item.id)">
                        <feather-icon icon="Trash2Icon" />
                    </b-button> -->

                  <div class="flex text-nowrap py-1">
                     <feather-icon
                        @click="update(data.item)"
                        v-b-modal.modal-update
                        icon="Edit3Icon"
                        size="16"
                        class="cursor-pointer danger"
                     />

                     <feather-icon
                        @click="detailEmploye(data.item)"
                        icon="EyeIcon"
                        class="mx-1 cursor-pointer"
                        size="16"
                     />
                     <feather-icon
                        @click="confirmText(data.item.id)"
                        icon="TrashIcon"
                        class="cursor-pointer"
                        size="16"
                     />
                  </div>
               </template>

               <template #cell(created_at)="data">
                  {{ format_date(data.item.created_at) }}
               </template>
            </b-table>

            <!-- Les boutons de pagination -->
            <div class="mx-2 mb-2 pb-1">
               <b-row>
                  <b-col
                     cols="12"
                     sm="6"
                     class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                     <span class="text-muted"></span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                     cols="12"
                     sm="6"
                     class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                     <b-pagination
                        v-model="currentPage"
                        :total-rows="userTotal"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                     >
                        <template #prev-text>
                           <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                           <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                     </b-pagination>
                  </b-col>
               </b-row>
            </div>
         </b-card>
         {{ __NEXT }}
      </div>
   </section>
</template>

<script>
import {
   BRow,
   BCol,
   BModal,
   BFormInput,
   BFormGroup,
   BButton,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
   reactive,
   ref,
   onMounted,
   toRef,
   computed,
} from '@vue/composition-api';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';
import { toast_sucess, toast_destory, toast_upgrade } from '@/utils/qToast';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import { unshift } from 'postcss-rtl/lib/affected-props';
import { qDecryptedJson } from '@/services/qeCrypt';
import QPermissions from '../../access/qPermissions.vue';
import QButtonCreated from '@/components/qButtonCreated.vue';
import index1 from '@/navigation/vertical/index1';

export default {
   components: {
      moment,
      VueTelInput,
      vSelect,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BModal,
      BFormGroup,
      VBModal,
      BForm,
      BImg,
      BLink,
      BForm,
      BFormCheckbox,
      BInputGroup,
      BInputGroupAppend,
      // validations
      ValidationProvider,
      ValidationObserver,
      QLoaderTable,
      QPermissions,
      QButtonCreated,
   },
   directives: {
      Ripple,
   },
   mixins: [togglePasswordVisibility],
   filters: {
      toSubtr(value, numb) {
         if (!value) return '';
         value = value.toString();
         if (value.length > numb) {
            return value.substring(0, numb) + '...';
         } else {
            return value;
         }
      },
   },
   data() {
      return {
         // validation
         nom: '',
         prenom: '',
         email: '',
         contact: '',
         phone: '',
         password: '',
         passwordEdit: '',
         edit_phone: '',
         contactFinal: '',
         indicatifFinal: '',
         edit_contactFinal: '',
         edit_indicatifFinal: '',
         localisation: '',
         loading: false,
         loading1: false,

         valideLocalisation: false,
         valideEditLocalisation: false,

         valideNom: false,
         validePrenom: false,
         valideContact: false,
         valideEmail: false,
         valideContactNumber: false,
         validePassword: false,
         valideEditPassword: false,
         valideContactNumber: false,
         ifMailExist: false,

         valideEditNom: false,
         valideEditPrenom: false,
         edit_valideContact: false,
         valideEditEmail: false,
         valideEditPassword: false,
         edit_valideContactNumber: false,
         email,
         userUp: '',
         returnData: '',
         required,
         user_exist: '',
         roles: [],
         valideExiste: '',
         edit_nom: '',
         edit_prenom: '',
         edit_email: '',
         edit_contact: '',
         edit_index: '',

         user_id: '',
         updateIndex: '',
         edit_localisation: '',

         valideRole: '',
         selectedRole: '',

         valideEditRole: '',
         selectedEditRole: '',
         userData: [],

         perPage: 30,
         currentPage: 1,
         userTotal: 0,
         tableColumns: [
            { key: 'nom', label: 'Nom & Prénoms', sortable: true },
            // { key: 'prenoms', label: 'Prénoms', sortable: true },
            { key: 'email', label: 'Email', sortable: true },
            // { key: 'indicateur', label: 'indicateur', sortable: true},
            { key: 'contact', label: 'Contact', sortable: true },
            // { key: 'localisation.formatted_address', label: 'Adresse' },
            { key: 'created_at', label: 'Date de création ', sortable: true },
            { key: 'actions' },
            { key: 'details', label: 'Permissions' },
         ],
         filtreUser: '',
         perPageOptions: [30, 50, 100, 150],
      };
   },

   setup(props, { root }) {
      const permStatus = computed(() => {
         return root.$store.state.qPermissions.statuts;
      });
      const GIVE = ref(false);
      const users = ref([]);
      const state = reactive({
         success: false,
         empty: false,
         warring: false,
      });
      const selectedPersonal = ref([]);
      const xGetAllPermission = ref([]);
      const xPermissionSelected = ref([]);

      //Get all permissions
      const getAllPermission = async () => {
         try {
            const { data } = await axios.get(URL.ROLE_PREMISSION);
            if (data) {
               let permisionName = [];

               for (let i = 0; i < data.permission.length; i++) {
                  const el = data.permission[i];

                  permisionName.push(el.split('-')[1]);
                  permisionName = permisionName.filter(function(
                     item,
                     pos,
                     self
                  ) {
                     return self.indexOf(item) == pos;
                  });
               }

               permisionName.forEach((el, index) => {
                  xGetAllPermission.value.push({
                     name: el,
                     permissions: data.permission.filter((perms) => {
                        return perms.split('-')[1] === permisionName[index];
                     }),
                  });
               });

               console.log('permisionName : ', permisionName);
               console.log('xGetAllPermission : ', xGetAllPermission.value);
            }
         } catch (error) {
            console.log(error);
         }
      };

      // Update permisions
      const updatePermissions = (userID) => {
         //

         xPermissionSelected.value = selectedPersonal.value.find(
            (el, index, self) => {
               return (
                  Object.keys(self[index])[index] === `${userID}`.toString()
               );
            }
         );

         //
         try {
            const { data } = axios.post(URL.PERSONAL_PERMISSIONS_UPDATE, {
               id: userID,
               permissions: Object.values(xPermissionSelected.value)[0],
            });
            if (data) {
               console.log(data);
            }
         } catch (error) {
            console.log(error);
         }
      };

      const __NEXT = computed(() => {
         if (GIVE.value === true) {
            users.value.length === 0
               ? ((state.empty = true), (state.success = false))
               : ((state.success = true), (state.empty = false));
         }
         return;
      });

      return {
         state,
         users,
         GIVE,
         __NEXT,
         permStatus,
         updatePermissions,
         selectedPersonal,
         getAllPermission,
         xGetAllPermission,
         xPermissionSelected,
      };
   },

   async mounted() {
      document.title = 'Employé - Ediqia';
      this.getAllPermission();
      google.maps.event.addDomListener(window, 'load', initialize);
      try {
         await axios.get(URL.EMPLOYE_LIST).then((response) => {
            this.userTotal = response.data.listeEmploye.length;
            this.users = response.data.listeEmploye;
            this.roles = response.data.listeRole;
            this.GIVE = true;

            this.users.forEach((el) => {
               if (el.permissions.length !== 0) {
                  this.selectedPersonal.push({
                     [`${el.id}`]: el.permissions.map((ol) => {
                        return ol.name;
                     }),
                  });
               } else {
                  this.selectedPersonal.push({
                     [`${el.id}`]: [],
                  });
               }
            });

            console.log('selectedPersonal', this.selectedPersonal);

            this.users.forEach((user) => {
               user.localisation = JSON.parse(user.localisation);
               // console.log(user);
            });
         });
      } catch (error) {
         console.log(error);
      }
   },

   computed: {
      passwordToggleIcon() {
         return this.passwordFieldType === 'password'
            ? 'EyeIcon'
            : 'EyeOffIcon';
      },
      // imgUrl() {
      //     if (store.state.appConfig.layout.skin === "dark") {
      //         // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //         this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
      //         return this.sideImg;
      //     }
      //     return this.sideImg;
      // },
   },

   methods: {
      //  detailClient(item) {
      //     const employe = item
      //     localStorage.setItem('employe', JSON.stringify(employe))
      //     this.$router.push('detail/employe')
      // },

      initialize() {
         var input = document.getElementById('autocomplete_search');
         var autocomplete = new google.maps.places.Autocomplete(input);
         autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace();
            // place variable will have all the information you are looking for.
            $('#lat').val(place.geometry['location'].lat());
            $('#long').val(place.geometry['location'].lng());
            this.localisation = place;
            localStorage.setItem('place', JSON.stringify(place));
            // console.log(this.address)
         });
      },

      format_date(value) {
         if (value) {
            return moment(String(value)).format('DD-MM-YYYY');
         }
      },

      validatePassword() {
         if (!this.password || this.password.length < 8) {
            this.validePassword = true;
            this.erreur = true;
         } else {
            this.validePassword = false;
            this.erreur = false;
         }
      },

      validateEditPassword() {
         if (!this.passwordEdit || this.passwordEdit.length < 8) {
            this.valideEditPassword = true;
            this.erreur = true;
         } else {
            this.valideEditPassword = false;
            this.erreur = false;
         }
      },

      detailEmploye(item) {
         const user = item;
         localStorage.setItem('user', JSON.stringify(user));
         this.$router.push('/detail/employe');
      },

      // confirm texrt
      confirmText(id, index) {
         this.$swal({
            title: 'Êtes vous sûr?',
            text: 'Ce employé sera supprimé définitivement !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
         }).then((result) => {
            if (result.value) {
               this.deleteUser(id, index);
            }
         });
      },
      // top end
      topEnd() {
         this.$swal({
            position: 'top-end',
            icon: 'success',
            title: 'employé enregistrée avec succès',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
               confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
         });
      },
      deleteUser(identifiant, index) {
         try {
            const id = {
               id: identifiant,
            };
            const config = {
               headers: {
                  Accept: 'application/json',
               },
            };
            axios
               .post(URL.EMPLOYE_DETROY, id, config)
               .then((response) => {
                  if (response.data) {
                     this.users = this.users.filter((user) => {
                        return user.id !== id.id;
                     });

                     console.log(this.users);

                     toast_destory(
                        this,
                        'success',
                        'top-right',
                        'employé supprimé avec succés !'
                     );
                  }
               })
               .catch((error) => {
                  console.log(error);
               });
         } catch (error) {
            console.log(error.type);
         }
      },
      update(item) {
         this.userUp = item;
         this.selectedEditRole = this.userUp.status_user;
         this.edit_nom = this.userUp.nom;
         this.edit_prenom = this.userUp.prenoms;
         this.edit_email = this.userUp.email;
         this.edit_phone = this.userUp.contact;
         this.edit_index = this.users.findIndex((el) => el.id === item.id);
         this.edit_localisation = this.userUp.localisation.formatted_address;
         this.user_id = this.userUp.id;
         // this.passwordEdit = this.userUp.password;

         console.log(item);
      },
      async updateUsers(bvModalEvt) {
         this.edit_contactFinal = this.edit_contact.formatted;
         const index = this.edit_index;
         this.edit_validateContact();
         this.validateEditPrenom();
         this.validateEditNom();
         this.validateEditRole();
         // this.validateEditPassword();
         this.validateEditLocalisation();
         // this.valideEditPassword
         console.log(index, this.user_id);

         if (this.error === true) {
            bvModalEvt.preventDefault();
         } else {
            try {
               this.loading1 = true;
               const data = {
                  role:
                     this.selectedEditRole === 'employee' ||
                     this.selectedEditRole === 'gestionnaire'
                        ? this.selectedEditRole
                        : this.selectedEditRole.name,
                  id: this.user_id,
                  nom: this.edit_nom,
                  prenoms: this.edit_prenom,
                  email: this.edit_email,
                  contact: this.edit_contactFinal,
                  indicateur: this.edit_indicatifFinal,
                  localisation:
                     JSON.parse(localStorage.getItem('place')) === null
                        ? this.userUp.localisation
                        : JSON.parse(localStorage.getItem('place')),
                  // password: this.passwordEdit,
               };

               const config = {
                  headers: {
                     Accept: 'application/json',
                  },
               };
               axios
                  .post(URL.USER_UPDATE, data, config)
                  .then((response) => {
                     this.loading1 = false;
                     this.$bvModal.hide('modal-update');
                     toast_sucess(this, 'success', 'top-right', 'Employé ');
                     this.users.forEach((user) => {
                        if (user.id === this.user_id) {
                           user.nom = this.edit_nom;
                           user.prenoms = this.edit_prenom;
                           user.email = this.edit_email;
                           user.contact = this.edit_contactFinal;
                           user.indicateur = this.edit_indicatifFinal;
                           user.localisation = JSON.parse(
                              localStorage.getItem('place')
                           );
                        }
                     });
                  })
                  .catch((error) => {
                     if (error.response) {
                        this.loading1 = false;
                        console.log(error.response.data);
                     }
                  });
            } catch (error) {
               this.loading1 = false;
               console.log(error);
            }
         }
      },
      isDigit(str) {
         return /^\d+$/.test(str);
      },

      validateEditRole() {
         if (this.selectedEditRole == '') {
            this.valideEditRole = true;
            this.erreur = true;
         } else {
            this.valideEditRole = false;
            this.erreur = false;
         }
      },

      validateRole() {
         if (this.selectedRole == '') {
            this.valideRole = true;
            this.erreur = true;
         } else {
            this.valideRole = false;
            this.erreur = false;
         }
      },

      validateEmail() {
         // valid email regex pattern
         const emailPattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
         if (!this.email.match(emailPattern) && this.email !== '') {
            this.valideEmail = true;
            this.erreur = true;
         } else {
            this.valideEmail = false;
            this.erreur = false;
         }
      },
      validateEditEmail() {
         // valid email regex pattern
         const emailPattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
         if (!this.edit_email.match(emailPattern) && this.edit_email !== '') {
            this.valideEditEmail = true;
         } else {
            this.valideEditEmail = false;
         }
      },
      validateContact() {
         this.contactFinal = this.contact.formatted;
         if (!this.contactFinal) {
            this.valideContact = true;
            this.erreur = true;
         } else {
            this.valideContact = false;
            this.erreur = false;
         }

         if (this.contactFinal && this.contactFinal.length < 8) {
            this.valideContactNumber = true;
            this.erreur = true;
         } else {
            this.valideContactNumber = false;
            this.erreur = false;
         }
      },
      validateEditContact() {
         if (!this.edit_contact) {
            this.valideEditContact = true;
            this.erreur = true;
         } else {
            this.valideEditContact = false;
            this.erreur = false;
         }
         if (this.edit_contact && this.isDigit(this.edit_contact) === false) {
            this.valideEditContactNumber = true;
         } else {
            this.valideEditContactNumber = false;
         }
      },
      edit_validateContact() {
         if (!this.edit_contactFinal) {
            this.edit_valideContact = true;
            this.erreur = true;
         } else {
            this.edit_indicatifFinal = this.edit_indicatifFinal =
               '+' + '' + this.edit_contact.country.dialCode;
            this.edit_valideContact = false;
            this.erreur = false;
         }
         if (this.edit_contactFinal && this.edit_contactFinal.length < 8) {
            this.edit_valideContactNumber = true;
         } else {
            this.edit_valideContactNumber = false;
         }
      },
      validateNom() {
         if (!this.nom) {
            this.valideNom = true;
            this.erreur = true;
         } else {
            this.valideNom = false;
            this.erreur = false;
         }
      },
      validateEditNom() {
         if (!this.edit_nom) {
            this.erreur = true;
            this.valideEditNom = true;
         } else {
            this.erreur = false;
            this.valideEditNom = false;
         }
      },
      validatePrenom() {
         if (!this.prenom) {
            this.validePrenom = true;
            this.erreur = true;
         } else {
            this.validePrenom = false;
            this.erreur = false;
         }
      },
      validateifMailExist(dataEmployer) {
         dataEmployer.filter((employer) => {
            if (employer.email === this.email) {
               this.ifMailExist = true;
               this.erreur = true;
            } else {
               this.ifMailExist = false;
               this.erreur = false;
            }
         });
      },
      validateEditPrenom() {
         if (!this.edit_prenom) {
            this.erreur = true;
            this.valideEditPrenom = true;
         } else {
            this.erreur = false;
            this.valideEditPrenom = false;
         }
      },

      validateLocalisation() {
         if (!this.localisation) {
            this.valideLocalisation = true;
            this.erreur = true;
         } else {
            this.valideLocalisation = false;
            this.erreur = false;
         }
      },

      validateEditLocalisation() {
         if (!this.edit_localisation) {
            this.valideEditLocalisation = true;
         } else {
            this.valideEditLocalisation = false;
         }
      },

      async handleOk(bvModalEvt) {
         let getAbomentStatut = qDecryptedJson(
            localStorage.getItem('qhze_022')
         );
         if (this.users.length > 10) {
            toast_upgrade(this, 'success', 'top-right', 'Employé ');
            this.$bvModal.hide('modal-login');
         } else {
            try {
               this.validateNom();
               this.validatePrenom();
               this.validateContact();
               this.validateRole();
               this.validatePassword();
               this.if_exist();
               this.validateifMailExist(this.users);
               // Prevent modal from closing
               // || this.valideRole
               if (
                  this.valideNom ||
                  this.validePrenom ||
                  this.valideContact ||
                  this.valideContactNumber ||
                  this.valideExiste ||
                  this.validePassword ||
                  this.ifMailExist
               ) {
                  bvModalEvt.preventDefault();
               } else {
                  this.indicatifFinal =
                     '+' + '' + this.contact.country.dialCode;
                  // console.log(this.indicatifFinal);
                  // localStorage.removeItem('place')

                  this.loading = true;
                  const newUser = {
                     nom: this.nom,
                     prenoms: this.prenom,
                     contact: this.contactFinal,
                     indicateur: this.indicatifFinal,
                     email: this.email,
                     localisation: JSON.parse(localStorage.getItem('place')),
                     password: this.password,
                  };

                  // console.log(newUser);
                  // this.topEnd();

                  const config = {
                     headers: {
                        Accept: 'application/json',
                     },
                  };

                  await axios
                     .post(URL.USER_CREATE, newUser, config)
                     .then((response) => {
                        this.userData = response.data.user_role;
                        newUser['created_at'] = moment()
                           .utc()
                           .toString();
                        newUser['id'] = this.userData.id;
                        this.users.unshift(response.data.user);

                        this.users.forEach((el) => {
                           if (el.permissions.length !== 0) {
                              this.selectedPersonal.push({
                                 [`${el.id}`]: el.permissions.map((ol) => {
                                    return ol.name;
                                 }),
                              });
                           } else {
                              this.selectedPersonal.push({
                                 [`${el.id}`]: [],
                              });
                           }
                        });

                        localStorage.removeItem('place');
                        this.$bvModal.hide('modal-login');
                        toast_sucess(this, 'success', 'top-right', 'Employé ');

                        this.loading = false;
                        this.nom = '';
                        this.prenom = '';
                        this.email = '';
                        this.contact = '';
                        this.indicateur = '';
                        this.localisation = '';
                        this.password = '';
                     });
               }
            } catch (error) {
               this.loading = false;
               console.log(error);
            }
         }
      },
      if_exist() {
         for (let index = 0; index < this.users.length; index++) {
            if (this.contactFinal == this.users[index].contact) {
               this.user_exist =
                  'Ce employé existe déjà! Veuillez vérifier le contact';
               this.valideExiste = true;
            } else {
               this.valideExiste = false;
            }
         }
      },
      contactEntier(e) {
         this.contact = e;
         //    console.log(this.phone)
      },
      changer(e) {
         console.log(this.phone);
         this.contact.country.name = e.name;
      },

      edit_contactEntier(e) {
         this.edit_contact = e;
         //    console.log(this.phone)
      },
      edit_changer(e) {
         console.log(this.phone);
         this.edit_contact.country.name = e.name;
      },
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.table-base {
   margin: 30px auto 0;
}

.tableau {
   box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
   width: 100%;
   margin: auto;
   border-radius: 13px;
}

.table-card thead tr {
   border-radius: 13px;
   background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
   background-color: rgb(68, 68, 68) !important;
   color: white;
}

.add-btn {
   position: absolute;
   right: 0;
   top: -50px;
   background-color: #450077;
}

.none {
   display: none;
}
.block {
   display: inline-block;
}
.a::hover {
   //   background-color: #450077;
   text-decoration: underline;
   color: #450077;
}

.red:after {
   content: ' *';
   color: red;
}
</style>
